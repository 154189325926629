var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box_1" },
    [
      _c("div", { staticClass: "form_login" }, [
        _vm._m(0),
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.codeValid,
                expression: "codeValid",
              },
            ],
            staticClass: "form-group",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "b-form-group",
              [
                _c("b-form-input", {
                  staticClass: "inputText",
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.user.name.$error,
                  },
                  attrs: { id: "user-name", placeholder: "Nome do Usuário" },
                  model: {
                    value: _vm.user.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "name", $$v)
                    },
                    expression: "user.name",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.submitted && _vm.$v.user.name.$error,
                        expression: "submitted && $v.user.name.$error",
                      },
                    ],
                    staticClass: "invalid-feedback",
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.user.name.required,
                            expression: "!$v.user.name.required",
                          },
                        ],
                      },
                      [_vm._v("Nome do Usuário é obrigatório.")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              [
                _c("b-form-input", {
                  staticClass: "inputText",
                  class: {
                    "is-invalid": _vm.submitted && _vm.$v.user.password.$error,
                  },
                  attrs: {
                    id: "pass-input",
                    type: "password",
                    placeholder: "Senha",
                  },
                  model: {
                    value: _vm.user.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "password", $$v)
                    },
                    expression: "user.password",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.submitted && _vm.$v.user.password.$error,
                        expression: "submitted && $v.user.password.$error",
                      },
                    ],
                    staticClass: "invalid-feedback",
                  },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.user.password.required,
                            expression: "!$v.user.password.required",
                          },
                        ],
                      },
                      [_vm._v("Senha é obrigatória.")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$v.user.password.minLength,
                            expression: "!$v.user.password.minLength",
                          },
                        ],
                      },
                      [_vm._v("Senha deve ter pelo menos 8 caracteres.")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.user.password && !_vm.$v.user.password.valid,
                            expression:
                              "user.password && !$v.user.password.valid",
                          },
                        ],
                      },
                      [
                        _c("div", { staticClass: "password-rules" }, [
                          _c("ul", { staticClass: "password-rules-list" }, [
                            _c("li", { staticClass: "password-digit" }, [
                              _vm._v("Pelo menos um número."),
                            ]),
                            _c("li", { staticClass: "password-lower-case" }, [
                              _vm._v("Pelo menos um caractere minúsculo."),
                            ]),
                            _c("li", { staticClass: "password-upper-case" }, [
                              _vm._v("Pelo menos um caractere maiúsculo."),
                            ]),
                            _c(
                              "li",
                              { staticClass: "password-special-character" },
                              [
                                _vm._v(
                                  "Pelo menos um caractere especial (!@#()-[{}]:;',?/*~$^+=.`|%)."
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "b-form-group",
              [
                _c("b-form-input", {
                  staticClass: "inputText",
                  class: {
                    "is-invalid":
                      _vm.submitted && _vm.$v.user.confirmPassword.$error,
                  },
                  attrs: {
                    type: "password",
                    id: "confirmPassword",
                    name: "confirmPassword",
                    placeholder: "Confirme senha",
                  },
                  model: {
                    value: _vm.user.confirmPassword,
                    callback: function ($$v) {
                      _vm.$set(_vm.user, "confirmPassword", $$v)
                    },
                    expression: "user.confirmPassword",
                  },
                }),
                _vm.submitted && _vm.$v.user.confirmPassword.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      !_vm.$v.user.confirmPassword.required
                        ? _c("span", [
                            _vm._v("Confirmação de senha é obrigatória."),
                          ])
                        : !_vm.$v.user.confirmPassword.sameAsPassword
                        ? _c("span", [_vm._v("Senhas devem ser iguais")])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "b-checkbox",
                  {
                    staticClass: "label-class",
                    model: {
                      value: _vm.checked,
                      callback: function ($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked",
                    },
                  },
                  [
                    _vm._v(" Concordar com o "),
                    _c("b-link", {
                      staticClass: "label-class",
                      attrs: {
                        target: "_blank",
                        href: "https://www.retextoar.com.br/termo-de-uso-e-privacidade/",
                      },
                      domProps: { textContent: _vm._s(_vm.term) },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "padding-bottom": "0px !important",
                  "margin-top": "15px !important",
                },
              },
              [
                _c("vue-recaptcha", {
                  ref: "recaptcha",
                  staticClass: "g-recaptcha",
                  attrs: {
                    sitekey: "6LclwE4fAAAAAA2ng7_J2HYYW34CHDgHjKLzucPA",
                  },
                  on: {
                    verify: function ($event) {
                      return _vm.onVerify()
                    },
                    expired: function ($event) {
                      return _vm.onExpired()
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "submit-button" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "newMonitorButton2",
                    attrs: { disabled: _vm.enableSubmit(), type: "submit" },
                  },
                  [_vm._v("Enviar")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.codeValid,
                expression: "!codeValid",
              },
            ],
            staticClass: "text-container",
          },
          [
            _c(
              "p",
              {
                staticClass: "text-content",
                staticStyle: { color: "rgb(210,5,5)" },
              },
              [_vm._v("Usuário já possui cadastro no sistema!")]
            ),
            _c("p", { staticClass: "text-content" }, [
              _vm._v(
                "Redirecionando para o login em " + _vm._s(_vm.timer) + "."
              ),
            ]),
          ]
        ),
      ]),
      _c("Carousel"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "flex-start",
          "align-content": "center",
          "align-items": "center",
        },
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/icons/retextoar-app.png"),
            width: "35px",
            height: "35px",
          },
        }),
        _c("p", { staticClass: "titleStyle" }, [_vm._v("Retextoar")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }